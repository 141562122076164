// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./aGI2b6m4j-0.js";

const cycleOrder = ["FWGstvqPF", "zg3G2FKbk"];

const serializationHash = "framer-d33xS"

const variantClassNames = {FWGstvqPF: "framer-v-26eb23", zg3G2FKbk: "framer-v-eeomhe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {C3NO8bylo: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "FWGstvqPF", "Variant 2": "zg3G2FKbk"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FWGstvqPF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FWGstvqPF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "zg3G2FKbk") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-26eb23", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FWGstvqPF"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({zg3G2FKbk: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<SVG className={"framer-8yrg46"} layout={"position"} layoutDependency={layoutDependency} layoutId={"oQKvZ3m1Z"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 22\"><g transform=\"translate(0.325 0.546)\" id=\"ss8901410403_1\"><path d=\"M 0 20.911 L 0 0 L 19.239 0 L 19.239 20.911 Z\" fill=\"transparent\"></path><path d=\"M 7.997 0.002 L 19.239 10.135 L 7.997 20.267 L 6.317 18.404 L 14.099 11.389 L 0.636 11.389 L 0.636 8.88 L 14.099 8.88 L 6.317 1.865 Z\" fill=\"rgb(36,36,36)\"></path></g></svg>"} svgContentId={8901410403} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d33xS.framer-de4fbe, .framer-d33xS .framer-de4fbe { display: block; }", ".framer-d33xS.framer-26eb23 { height: 22px; overflow: hidden; position: relative; width: 20px; }", ".framer-d33xS .framer-8yrg46 { flex: none; height: 22px; left: calc(50.00000000000002% - 20px / 2); position: absolute; top: calc(50.00000000000002% - 22px / 2); width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zg3G2FKbk":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraGI2b6m4j: React.ComponentType<Props> = withCSS(Component, css, "framer-d33xS") as typeof Component;
export default FrameraGI2b6m4j;

FrameraGI2b6m4j.displayName = "iconButton";

FrameraGI2b6m4j.defaultProps = {height: 22, width: 20};

addPropertyControls(FrameraGI2b6m4j, {variant: {options: ["FWGstvqPF", "zg3G2FKbk"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameraGI2b6m4j, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})